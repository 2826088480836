<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { composeSearchCategories } from '@/helpers/search/composeSearchCategories';
import { composeRegularProducts } from '@/helpers/search/composeRegularProducts';
import { getSrcSetForMedia } from '@shopware-pwa/helpers-next';
import { routerPush } from '~~/helpers/routerPush';
import { getMediaQuery } from '~/helpers/getMediaQuery';
import {
  ListingResult,
  Product,
  ShopwareSearchParams,
} from '@shopware-pwa/types';
import { IProductSearchResult, ISearchParams } from '~~/types/search';
import { handleClick } from '@/helpers/clickHandler';

const { push, currentRoute } = useRouter();
const { isLoggedIn } = useUser();
const { mainNavigation } = useNavigation();
const { locale, t } = useI18n();
const { refreshSessionContext } = useSessionContext();
const { isMobileOrTablet } = getMediaQuery();

let currentRouteLength = currentRoute.value?.params?.all?.length ?? 0;
const fastNavigation = ref(true);

if (currentRouteLength > 0) {
  fastNavigation.value = false;
}

const isWishlistPageOpen1 = ref(false);

provide('isWishlistPageOpen', isWishlistPageOpen1);

//TODO: import from design-system
interface IOption {
  id: string | null;
  name: string;
  key?: string;
  type?: string;
  locale?: string | null;
  selected?: boolean;
  code: string;
}

const props = withDefaults(
  defineProps<{
    wishlistCount?: number | string;
    cartCount?: number | string;
    languages: IOption[];
    showMobileSearch?: boolean;
  }>(),
  {
    wishlistCount: 0,
    cartCount: 0,
    languages: () => [],
    showMobileSearch: false,
  }
);

const emits = defineEmits<{
  (e: 'languageChange', language: IOption): void;
  (e: 'toggleRegistrationModal'): void;
  (e: 'closeSearch'): void;
}>();

type ISubcategories = {
  id: string;
  title: string;
  to: string;
  highlight: any;
  externalLink: any;
  linkNewTab: any;
  active: boolean;
}[];

const showFastNavigation = () => {
  if (currentRouteLength > 0) {
    return (fastNavigation.value = false);
  }

  if (currentRouteLength === 0) {
    return (fastNavigation.value = true);
  }
};

watch(
  () => currentRoute.value?.params?.all?.length,
  (newValue) => {
    currentRouteLength = newValue;
    showFastNavigation();
  }
);

watch(mainNavigation, () => {
  categoriesRef.value = setMainNavigationMapping();
});

// for header wishlist icon active if on wishlist page LSWS-768
watch(
  () => currentRoute.value?.name,
  (newValue) => {
    if (newValue !== 'locale-wishlist') {
      isWishlistPageOpen1.value = false;
    }
  }
);

// const activeCategoryNameRef = ref(currentRoute.value?.params?.all[0]);

// onMounted(() => {
//   activeCategoryNameRef.value = currentRoute.value?.params?.all[0];
// });

const setMainNavigationMapping = () => {
  return (
    mainNavigation.value?.map((category) => {
      //TODO: fix types
      const media: any = category.customFields
        ?.custom_navigation_image_1_media_media as unknown;
      const customfields = category.customFields;

      const bannerData: any = [];
      let banner1 = {};
      if (media?.url) {
        banner1 = {
          imageSrc: media?.url || '',
          headline: customfields.custom_navigation_image_headline_1 || '',
          subheadline: customfields.custom_navigation_image_subheadline_1 || '',
          imageLink: customfields.custom_navigation_link_image_1 || '',
          whiteFont: customfields.custom_navigation_image_white_font_1,
          srcset: media ? getSrcSetForMedia(media as any) : '',
          altText: media?.alt || '',
          titleText: media?.title || '',
        };

        bannerData.push(banner1);
      }

      const media2: any = category.customFields
        ?.custom_navigation_image_2_media_media as unknown;
      let banner2 = {};
      if (media2?.url) {
        banner2 = {
          imageSrc: media2?.url || '',
          headline: customfields.custom_navigation_image_headline_2 || '',
          subheadline: customfields.custom_navigation_image_subheadline_2 || '',
          imageLink: customfields.custom_navigation_link_image_2 || '',
          whiteFont: customfields.custom_navigation_image_white_font_2,
          srcset: media2 ? getSrcSetForMedia(media2 as any) : '',
          altText: media2?.alt || '',
          titleText: media2?.title || '',
        };

        bannerData.push(banner2);
      }

      let subcategories: ISubcategories = [];
      category.children?.forEach((subcategory) => {
        subcategories.push({
          id: subcategory.id,
          title: subcategory.name || '',
          to: subcategory.externalLink
            ? subcategory.externalLink
            : subcategory.internalLink
              ? `/${subcategory.internalLink}`
              : `/${subcategory.seoUrls?.[0]?.seoPathInfo}` || '',
          highlight:
            subcategory.customFields?.custom_navigation_headline_colored ||
            false,
          externalLink: !!(
            subcategory.externalLink || subcategory.internalLink
          ),
          linkNewTab: subcategory.linkNewTab,
          active: subcategory.active,
        });
      });
      return {
        id: category.id,
        subtitle:
          category.customFields &&
          category.customFields?.custom_navigation_subheadline
            ? category.customFields?.custom_navigation_subheadline
            : '',
        title: category.translated.name || '',
        to: category.externalLink
          ? category.externalLink
          : category.internalLink
            ? `/${category.internalLink}`
            : `/${category.seoUrls?.[0]?.seoPathInfo}` || '',
        highlight:
          category.customFields?.custom_navigation_headline_colored || false,
        subcategories: subcategories,
        externalLink: !!(category.externalLink || category.internalLink),
        linkNewTab: category.linkNewTab,
        bannerData: bannerData,
        active: category.active,
      };
    }) || []
  );
};

const categoriesRef = ref(setMainNavigationMapping());
const categories = computed(() => categoriesRef.value);

const frontRoute = computed(() => `/${locale.value}/`);

const goToFrontpage = () => {
  push(frontRoute.value);
};

const config = useRuntimeConfig();
const brandStores = toRaw(config.brandStores);

const isSidebarOpen = inject('isSidebarOpen') as boolean;

const { initDataLayer, pushEvents } = useJentis();

// jentis
const isOpenCart = () => {
  const dataLayer = initDataLayer({ resourceType: 'frontend.cart.offcanvas' });

  dataLayer.load().then((result) => pushEvents(result?.data));
};

const goToBrand = (brand: string) => {
  Object.keys(brandStores).find((element) => {
    if (element === brand.toLowerCase()) {
      window.location.href = brandStores[element] + `/${locale.value}/`;
    }
  });
};

const showRegistrationModal = inject('showRegistrationModal') as Ref<boolean>;

//TODO: write composable
const goToAccount = () => {
  if (!isLoggedIn.value) {
    emits('toggleRegistrationModal');
    showRegistrationModal.value = !showRegistrationModal.value;
  } else {
    push(`/${locale.value}/account/`);
  }
};

const goToLogout = () => {
  push(`/${locale.value}/logout/`);
};

/**
 * Search
 */
const productList: any = ref([]);
const categoryList: any = ref([]);
const regularProductTotal: any = ref(0);
const specialProductTotal: any = ref(0);
const categoryTotal: any = ref(0);

const { search, getElements, getCurrentListing } = useListing({
  listingType: 'productSearchListing',
});

const queryString = ref('');
const searchLoading = ref(true);
const searchLoadingMobile = ref(false);

const searchQuery = async (query: string) => {
  queryString.value = query.trim();

  if (queryString.value.length >= 3) {
    searchLoading.value = true;
    searchLoadingMobile.value = true;

    await search({
      limit: 50,
      p: 1,
      query: queryString.value,
      'manufacturer-filter': false,
      'price-filter': false,
      'rating-filter': false,
      'shipping-free-filter': false,
      // 'property-filter': false
    } as Partial<ShopwareSearchParams> & ISearchParams);

    searchLoading.value = false;
    searchLoadingMobile.value = false;
  }
};

watch(
  getCurrentListing,
  (result: Partial<ListingResult<Product> & IProductSearchResult> | null) => {
    productList.value = composeRegularProducts(
      getElements.value,
      locale.value,
      t
    );

    const categories =
      result?.extensions?.multiSearchResult?.searchResults?.category?.elements;
    if (categories) {
      categoryList.value = composeSearchCategories(
        Object.values(categories),
        `/${locale.value}/`
      );
    }

    regularProductTotal.value =
      (result?.extensions?.totalSearchResult?.regular || 0) +
      (result?.extensions?.totalSearchResult?.sale || 0);
    specialProductTotal.value =
      result?.extensions?.totalSearchResult?.sale || 0;
    categoryTotal.value =
      result?.extensions?.multiSearchResult?.searchResults?.category.total || 0;
  }
);

const showAllResults = async () => {
  if (isMobileOrTablet.value) {
    await refreshSessionContext();
  }
  await push(`/${locale.value}/search/?query=${queryString.value}`);
};

const showSearch = toRef(props, 'showMobileSearch');
const closeSearch = () => {
  showSearch.value = false;
  emits('closeSearch');
};

const { items } = useWishlist();
const { pushSuccess } = useNotifications();
const wishlistProduct = ref();

const { isInWishlist, addToWishlist, removeFromWishlist } =
  useProductWishlist(wishlistProduct);

const toggleToFavorites = async (product: any) => {
  wishlistProduct.value = await product;

  if (isInWishlist.value) {
    await removeFromWishlist();
    await pushSuccess(t('components.wishlist.removeFromWishlist'));
  } else {
    await addToWishlist();
    await pushSuccess(t('components.wishlist.addToWishlist'));
  }
};
</script>

<template>
  <Header
    :categories="categories"
    :brand="config.BRAND"
    :link="frontRoute"
    :languages="languages"
    :wishlist-count="wishlistCount"
    :cart-count="cartCount"
    :product-list="productList"
    :regular-product-total="regularProductTotal"
    :special-product-total="specialProductTotal"
    :category-list="categoryList"
    :category-total="categoryTotal"
    :search-loading="searchLoading"
    :wish-list="items"
    @go-to-frontpage="goToFrontpage()"
    @open-cart="(isSidebarOpen = true), isOpenCart()"
    @open-account="goToAccount"
    @open-wishlist="(isWishlistPageOpen1 = true), push(`/${locale}/wishlist/`)"
    @logout="goToLogout"
    @language-change="(language: IOption) => emits('languageChange', language)"
    @open-store-finder="push(`/${locale}/filialfinder/`)"
    @click-brand="goToBrand"
    @search-query-input="searchQuery"
    @open-product="(product: any) => push(product.link)"
    @show-all-results="showAllResults"
    @toggle-to-favorites="toggleToFavorites"
  >
    <template #topBarLink="{ getBrandIcon, topBarBrand }">
      <span class="top-navigation-items__link" @click="goToBrand(topBarBrand)">
        <IconSprite :name="getBrandIcon(topBarBrand)" icon2000 />
      </span>
    </template>
    <template #topNavigation>
      <!-- TODO: can we remove ClientOnly?? -->
      <ClientOnly>
        <TopNavigation
          :categories="categories"
          :show-fast-navigation="fastNavigation"
          class="header__navigation"
          @go-to-link="handleClick"
        >
          <template #topNavigationItem="{ category }">
            <a
              v-if="category.externalLink"
              :href="category.to"
              class="top-navigation-items__link"
              :class="{ 'is-active': true }"
              :target="category.linkNewTab ? '_blank' : '_self'"
            >
              <span class="top-navigation-items__headline">
                {{ category.title }}
              </span>
              <span
                v-if="category.subtitle"
                class="top-navigation-items__subheadline"
              >
                {{ category.subtitle }}
              </span>
            </a>
            <LocaleRouterLink
              v-else
              :to="category.to"
              class="top-navigation-items__link"
            >
              <span class="top-navigation-items__headline">
                {{ category.title }}
              </span>
              <span
                v-if="category.subtitle"
                class="top-navigation-items__subheadline"
              >
                {{ category.subtitle }}
              </span>
            </LocaleRouterLink>
          </template>

          <template #megaMenuItems="{ megaMenuCategory }">
            <a
              v-if="megaMenuCategory.externalLink"
              :href="megaMenuCategory.to"
              class="mega-menu__link"
              :class="{ 'is--highlight': megaMenuCategory?.highlight }"
              :target="megaMenuCategory.linkNewTab ? '_blank' : '_self'"
              >{{ megaMenuCategory.title }}</a
            >
            <LocaleRouterLink
              v-else
              :to="megaMenuCategory.to"
              class="mega-menu__link"
              :class="{ 'is--highlight': megaMenuCategory?.highlight }"
            >
              {{ megaMenuCategory.title }}
            </LocaleRouterLink>
          </template>
        </TopNavigation>
      </ClientOnly>
    </template>
  </Header>
  <ClientOnly>
    <div v-show="showSearch">
      <MobileSearch
        :disabled="false"
        :category-list="categoryList"
        :product-list="productList"
        :loading="searchLoadingMobile"
        :category-total="categoryTotal"
        :regular-product-total="regularProductTotal"
        :special-product-total="specialProductTotal"
        :wish-list="items"
        @search-query-input="searchQuery"
        @open-product="(product: any) => push(product.link)"
        @show-all-results="showAllResults"
        @cancel="closeSearch"
        @toggle-to-favorites="toggleToFavorites"
      />
    </div>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.top-bar_container_block-button {
  .top-navigation-items__link {
    display: flex;
  }
}
</style>
