import { Product } from '@shopware-pwa/types';
import { getSmallestThumbnailUrl } from '@shopware-pwa/helpers-next';
import { getProductProperties } from '~/helpers/getProductProperties';
import { EBrand } from '~/types/brand';
import { composeProductBadges } from '~/helpers/composeProductBadges';
import { getSrcSetForMedia } from '@shopware-pwa/helpers-next';

// const { items } = useWishlist();
const { getFormattedPrice } = usePrice();

export const composeRegularProducts = (
  productList: Product[] | undefined | null,
  locale: string,
  t: any
) => {
  const products: any = [];

  productList?.forEach((product) => {
    const badgeList = composeProductBadges(product, t);

    const {
      productColorName,
      productMaterial,
      productShoeType,
      productShoeClosure,
      productAdditionalInfo,
    } = getProductProperties(
      product?.properties || [],
      (product?.manufacturer?.customFields?.ln_brand_internal_name as EBrand) ||
        EBrand.LEGERO
    );

    products.push({
      id: product.id,
      productName: product.translated.name + ' - ' + productColorName || '',
      productAlt: product?.cover?.media?.alt,
      productTitle: product?.cover?.media?.title,
      promotion: productAdditionalInfo || '',
      article: [productShoeType, productMaterial, productShoeClosure]
        .filter(Boolean)
        .join(', '),
      src: getSmallestThumbnailUrl(product?.cover?.media) || '',
      srcset: product?.cover?.media?.thumbnails
        ? getSrcSetForMedia(product?.cover?.media as any)
        : '',
      regularPrice: product.calculatedPrice?.listPrice?.price // product.calculatedPrice.unitPrice === special price
        ? getFormattedPrice(product.calculatedPrice?.listPrice?.price)
        : getFormattedPrice(product.calculatedPrice?.unitPrice),
      specialPrice: product.calculatedPrice?.listPrice?.price
        ? getFormattedPrice(product.calculatedPrice?.unitPrice)
        : '',
      // link: product.seoUrls
      //   ? `/${locale}/${product?.seoUrls[0]?.seoPathInfo}`
      //   : '',
      link:
        `/${locale}/${product?.seoUrls?.[0]?.seoPathInfo}` ||
        `/${locale}${product?.seoUrls?.[0]?.pathInfo}` ||
        '',
      inWishList: false,
      // TODO: Fix shopware context is missing
      // inWishList: items.value.includes(product.id),
      badgeList,
    });
  });

  return products;
};
