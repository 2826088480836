import { IExtendedCategory } from '@/types/category';

export const composeSearchCategories = (
  navigationElements: IExtendedCategory[] | undefined | null,
  linkPrefix = ''
) => {
  const categories: any = [];

  navigationElements?.forEach((navigation) => {
    categories.push({
      id: navigation.id,
      title: navigation.translated.name,
      link: navigation.seoUrls
        ? linkPrefix + navigation.seoUrls[0]?.seoPathInfo
        : '',
      subcategories:
        navigation.children?.map((child) => ({
          id: child.id,
          title: child.translated.name,
          link: child.seoUrls ? linkPrefix + child.seoUrls[0]?.seoPathInfo : '',
        })) || [],
    });
  });

  return categories;
};
